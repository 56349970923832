<template>
  <v-container class="mb-10">
    <h1>Wallet</h1>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteSettings.phone }}
    </p>
    <v-overlay opacity="0.8" :value="gettingWallet">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12" md="6">
        <v-card elevation="3" color="primary" rounded="lg" class="pa-2">
          <v-card-text class="white--text">
            <p class="overline white--text opacity-80 mb-0">
              Your {{ getCurrencyCode(user.country) }} Wallet
            </p>
            <span class="opacity-85">Available Balance</span>
            <h4 class="text-h4">
              {{ userCurrency }} {{ numberWithCommas(wallet.balance) || 0 }}
            </h4>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="d-block d-sm-flex">
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-btn
              depressed
              to="/user/wallet/withdraw"
              class="mt-2 mx-2 text-center"
            >
              Withdraw
            </v-btn>
            <v-btn
              depressed
              to="/user/wallet/fund"
              class="mt-2 mx-2 text-center"
              v-if="userCountry === COUNTRIES.NG"
            >
              Fund
            </v-btn>
            <v-btn
              depressed
              to="/user/wallet/transfer"
              class="mt-2 mx-2 text-center"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="6" cols="12">
        <v-card elevation="3" color="brown" rounded="lg" class="pa-2">
          <v-card-text class="white--text">
            <p class="overline white--text opacity-80 mb-0">
              Your referral bonus wallet
            </p>
            <span class="opacity-85">Available Balance</span>
            <h4 class="text-h4">
              {{ numberWithCommas(wallet.bonus) }}
            </h4>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="d-block d-sm-flex">
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-btn
              depressed
              @click.stop="convertDialog = true"
              class="mt-2 mx-2 text-center"
            >
              Convert
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col md="6" cols="12">
        <v-card elevation="3" color="blue" rounded="lg" class="pa-2">
          <v-card-text class="white--text">
            <p class="overline white--text opacity-80 mb-0">
              Your snappy token wallet
            </p>
            <p class="overline white--text opacity-70 mb-0">
              1 Snappy Token =
              {{ getToken }}
            </p>
            <span class="opacity-85">Available token</span>
            <h4 class="text-h4">{{ numberWithCommas(wallet.token) }}</h4>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="d-block d-sm-flex">
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-btn
              depressed
              @click.stop="tokenDialog = true"
              class="mt-2 mx-2 text-center"
            >
              Convert
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-dialog max-width="500" v-model="tokenDialog">
        <v-form ref="convertForm" @submit.prevent="validate('token')">
          <v-card>
            <v-container>
              <v-card-title>Convert Token</v-card-title>
              <v-card-text>
                <p>
                  Your currently have
                  <b>{{ wallet.token }} Snappy Token</b> in your token wallet
                  but it is not spendable.
                </p>
                <p>
                  To make token spendable, enter the amount of token you want to
                  convert to spendable, this is credited to your deposit wallet
                  upon successful conversion.
                </p>
                <p>
                  Please note that the minimum token you can convert to your
                  deposit wallet is
                  <b> {{ getMinimumToken }}</b
                  >. Ensure you have earned up to that before you proceed
                </p>
                <v-text-field
                  filled
                  type="number"
                  label="Amount to Convert"
                  :rules="[
                    (v) =>
                      (v && v <= wallet.token) ||
                      'You can\'t convert your present bonus'
                  ]"
                  v-model="tokenAmount"
                ></v-text-field>
                <v-btn
                  :loading="loadingConvertToken"
                  :disabled="tokenAmount <= 0"
                  type="submit"
                  color="primary"
                  large
                  block
                  >CONVERT</v-btn
                >
              </v-card-text>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog max-width="500" v-model="convertDialog">
        <v-form ref="convertForm" @submit.prevent="validate('bonus')">
          <v-card>
            <v-container>
              <v-card-title>Convert Bonus</v-card-title>
              <v-card-text>
                <p>
                  Your currently have
                  <b> {{ numberWithCommas(Number(wallet.bonus).toFixed(2)) }}</b>
                  in your bonus wallet but it is not spendable.
                </p>
                <p>
                  To make bonus spendable, enter the amount of bonus you want to
                  convert to spendable, this is credited to your deposit wallet
                  upon successful conversion.
                </p>
                <p>
                  Please note that the minimum amount you can convert to you
                  deposit wallet is
                  <b> {{ getMinimumBonus }}</b
                  >. Ensure you have earned up to that before you proceed
                </p>
                <v-text-field
                  filled
                  type="number"
                  label="Amount to Convert"
                  :rules="[
                    (v) =>
                      (v && v <= wallet.bonus) ||
                      'You can\'t convert your present bonus'
                  ]"
                  v-model="amount"
                ></v-text-field>
                <v-btn
                  :loading="loadingConvert"
                  :disabled="amount <= 0"
                  type="submit"
                  color="primary"
                  large
                  block
                  >CONVERT</v-btn
                >
              </v-card-text>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog>
      <!-- <v-col cols="12" md="6"> </v-col>
      <v-col cols="12">
        <v-card flat class="pa-8" rounded="lg">
          <no-transaction></no-transaction>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
// import noTransaction from '../../../components/noTransaction.vue'
import walletApi from '../../../api/users/wallet'
import bonusApi from '../../../api/users/bonus'
import { mapState } from 'vuex'
import { numberWithCommas } from '../../../utils/helpers'
import { COUNTRIES, getCurrencyCode } from '../../../utils/countryHelpers'

export default {
  data: () => ({
    COUNTRIES,
    getCurrencyCode,
    numberWithCommas,
    tokenDialog: false,
    convertDialog: false,
    banks: 0,
    loadingConvert: false,
    gettingWallet: false,
    amount: '',
    loadingConvertToken: false,
    tokenAmount: ''
  }),
  components: {
    // noTransaction
  },
  computed: {
    ...mapState({
      wallet: (state) => state.user.wallet,
      user: (state) => state.user.user,
      siteSettings: (state) => state.user.siteSettings,
      countrySetting: (state) => state.user.countryCurrency,
      getMinimumToken() {
        return this.countrySetting?.[this.userCountry.toLowerCase()]
          .minimumToken
      },
      getMinimumBonus() {
        return this.countrySetting?.[this.userCountry.toLowerCase()]
          .minimumBonus
      },
      getToken() {
        return (
          this.countrySetting?.[this.userCountry?.toLowerCase()]?.tokenRate +
          ' ' +
          this.userCurrency
        )
      }
    })
  },
  created() {
    this.$store.dispatch('user/getSiteSettings')
    this.$store.dispatch('user/getCountryCurrency')
    this.getWalletData()
  },
  methods: {
    copy(copy) {
      const input = document.createElement('input')
      input.value = copy

      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$store.dispatch('alert', { message: 'Copied!', time: 2000 })
    },
    validate(type) {
      if (this.$refs.convertForm.validate()) {
        if (type === 'bonus') this.convertBonus('bonus')
        else this.convertBonus('token')
      }
    },
    async convertBonus(type) {
      if (type === 'bonus') {
        this.loadingConvert = true
      } else {
        this.loadingConvertToken = true
      }
      const amount = type === 'bonus' ? this.amount : this.tokenAmount
      const res = await bonusApi.bonus().convert(amount, type)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingConvert = false
        this.loadingConvertToken = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Convert successful',
        status: true
      })
      this.getWalletData()
      this.amount = ''
      this.loadingConvert = false
      this.convertDialog = false
      this.loadingConvertToken = true
      this.tokenDialog = false
    },
    async getWalletData() {
      this.gettingWallet = true
      const res = await walletApi.data().getWallet()
      this.$store.commit('user/setWalletData', res.data.data)
      this.gettingWallet = false
    }
  }
}
</script>

<style></style>
