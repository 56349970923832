import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/bonus`

export default {
  bonus() {
    return {
      convert: async (amount, type) => {
        const endpoint = `${URL}/convert`
        const data = { amount, type }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
